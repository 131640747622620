import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class monthlyLeadsTargetService {

  constructor(private http: HttpClient) {}

  //monthly target lead apis
  getMonthYearHospitalWise(month: number, year: number, hospitalId: number) {
    return this.http.get(`${environment.patient_registration_prefix_url}/month-year-hosp-wise?month=${month}&year=${year}&hosp_id=${hospitalId}`);
  }

  updateMonthlyLeadSourceTarget(hospitalId: number, month: number, year: number, monthly_leads_target_id: string, payload: any) {
    return this.http.put(`${environment.patient_registration_prefix_url}/monthly-lead-source-target/${monthly_leads_target_id}?month=${month}&year=${year}&hosp_id=${hospitalId}`, payload);
  }

  createMonthlyLeadSourceTarget(hospitalId: number, month: number, year: number, payload: any) {
    return this.http.post(`${environment.patient_registration_prefix_url}/monthly-lead-source-target?hosp_id=${hospitalId}&month=${month}&year=${year}`, payload);
  }

  //daily target lead apis
  updateDailyLeadSourceInput(daily_leads_target_id: string, daily_input_date: string, payload: any) {
    return this.http.put(`${environment.patient_registration_prefix_url}/daily-lead-source-input/${daily_leads_target_id}?daily_input_date=${daily_input_date}`, payload);
  }

  createDailyLeadSourceInput(month: number, year: number, payload: any) {
    return this.http.post(`${environment.patient_registration_prefix_url}/daily-lead-source-input?month=${month}&year=${year}`, payload);
  }

  getDailyLeadSourceInput(daily_input_date: string) {
    return this.http.get(`${environment.patient_registration_prefix_url}/daily-lead-source-input?daily_input_date=${daily_input_date}`)
  }




  
}
