<div class="custom-card card p-3">
  <div class="d-flex align-items-center gap-3">
    <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-lg"
      routerLink="/report" [title]="'Go Back'"></button>
    <h4 class="fw-bold mb-0">Monthly Target Leads</h4>
  </div>
  <div class="d-flex gap-2 align-items-end">
    <div class="col-auto select-block">
      <label class="text-dark text-bold">Select Hospital:</label>
      <p-dropdown [options]="hospitalOptions" optionLabel="name" (onChange)="onDropdownChange($event)"
        [(ngModel)]="selectedHospital" placeholder="select hospital"></p-dropdown>
    </div>
    <div class="col-auto">
      <label class="text-dark text-bold">Select Month:</label>
      <p-calendar [(ngModel)]="timeLineDate" view="month" dateFormat="mm/yy" [readonlyInput]="true"
        (onSelect)="onDateChange($event)" [inputStyle]="{ 'text-align': 'center' }"
        [ngModelOptions]="{ standalone: true }" [maxDate]="maxDate" [yearNavigator]="true" [yearRange]="'2020:' + currentYear">
      </p-calendar>
    </div>
  </div>
</div>

<section class="mt-4 mb-45">
  <form [formGroup]="marketingLeadForm" (ngSubmit)="handleOnSubmit()">
    <div class="table-section">
      <table>
        <thead>
          <tr>
            <th rowspan="2" class="common bg-navy-dark text-white" style="width: 5%;">S. NO</th>
            <th rowspan="2" class="common bg-navy-dark text-white" style="width: 15%;">SOURCE</th>
            <th colspan="3" class="common bg-navy-dark text-white" style="width: 20%;">{{ currentMonthYear }}</th>
            <th colspan="3" class="common bg-green-dark text-white" style="width: 20%;">{{ previousMonth1Year }}</th>
            <th colspan="3" class="common bg-purpal-dark text-white" style="width: 20%;">{{ previousMonth2Year }}</th>
          </tr>
          <tr>
            <th class="common bg-navy-dark text-white" style="width: 6.66%;">NEW</th>
            <th class="common bg-navy-dark text-white" style="width: 6.66%;">OLD</th>
            <th class="common bg-navy-dark text-white" style="width: 6.66%;">RPP</th>
            <th class="common bg-green-dark text-white" style="width: 6.66%;">NEW</th>
            <th class="common bg-green-dark text-white" style="width: 6.66%;">OLD</th>
            <th class="common bg-green-dark text-white" style="width: 6.66%;">RPP</th>
            <th class="common bg-purpal-dark text-white" style="width: 6.66%;">NEW</th>
            <th class="common bg-purpal-dark text-white" style="width: 6.66%;">OLD</th>
            <th class="common bg-purpal-dark text-white" style="width: 6.66%;">RPP</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of dailyMarketingReportData; let i = index;">
            <td class="common bg-navy-light text-dark">{{ i + 1 }}</td>
            <td class="common bg-navy-light text-dark">{{ data.leadSourceName }}</td>
            <td class="common bg-navy-light text-dark">
              <input class="common bg-navy-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" type="number" [min]="0"
                [formControlName]="'new_' + i" />
            </td>
            <td class="common bg-navy-light text-dark">
              <input class="common bg-navy-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" type="number" [min]="0"
                [formControlName]="'old_' + i" />
            </td>
            <td class="common bg-navy-light text-dark">
              <input class="common bg-navy-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" type="number" [min]="0"
                [formControlName]="'plan_' + i" />
            </td>
            <td class="common bg-blue-light text-dark">
              {{ data.previousMonth1WithYear?.new }}
            </td>
            <td class="common bg-blue-light text-dark">
              {{ data.previousMonth1WithYear?.old }}
            </td>
            <td class="common bg-blue-light text-dark">
              {{ data.previousMonth1WithYear?.plan }}
            </td>
            <td class="common bg-purpal-light text-dark">
              {{ data.previousMonth2WithYear?.new }}
            </td>
            <td class="common bg-purpal-light text-dark">
              {{ data.previousMonth2WithYear?.old }}
            </td>
            <td class="common bg-purpal-light text-dark">
              {{ data.previousMonth2WithYear?.plan }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end my-10">
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</section>