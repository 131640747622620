<div class="custom-card card p-3">
  <div class="d-flex align-items-center gap-3">
    <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-lg"
      routerLink="/report" [title]="'Go Back'"></button>
    <h4 class="fw-bold mb-0">Daily Target Leads</h4>
  </div>
  <div class="d-flex gap-2 align-items-end">
    <div class="col-auto">
      <label class="text-dark text-bold">Select Date:</label>
      <p-calendar class="custom-calender" [(ngModel)]="timeLineDate" [readonlyInput]="false" dateFormat="dd/mm/yy"
        (onSelect)="onDateChange($event)" (onInput)="validateManualDate($event)"
        [inputStyle]="{ 'text-align': 'start' }" [ngModelOptions]="{ standalone: true }" [maxDate]="maxSelectableDate" [yearNavigator]="true" [yearRange]="'2020:' + currentYear">
      </p-calendar>
    </div>
  </div>
</div>

<section class="mt-4 mb-45">
  <form [formGroup]="dailyTargetForm" (ngSubmit)="handleDailyTargetSubmit()">
    <div class="table-section">
      <table>
        <thead>
          <tr>
            <th rowspan="2" class="common bg-navy-dark text-white w-8">S. NO</th>
            <th rowspan="2" class="common bg-navy-dark text-white">SOURCE</th>
            <th colspan="2" class="common bg-navy-dark text-white w-10">{{ timeLineDate | date:
              'dd/MM/yyyy' }}</th>
            <th colspan="2" class="common bg-green-dark text-white w-10">{{ previousDay1 | date: 'dd/MM/yyyy' }}
            </th>
            <th colspan="2" class="common bg-purple-dark text-white w-10">{{ previousDay2 | date: 'dd/MM/yyyy' }}
            </th>
          </tr>
          <tr>
            <th class="common bg-navy-dark text-white w-10">Emoneeds</th>
            <th class="common bg-navy-dark text-white w-10">GK</th>
            <th class="common bg-green-dark text-white w-10">Emoneeds</th>
            <th class="common bg-green-dark text-white w-10">GK</th>
            <th class="common bg-purple-dark text-white w-10">Emoneeds</th>
            <th class="common bg-purple-dark text-white w-10">GK</th>
          </tr>
        </thead>
        <tbody formArrayName="leadSources">
          <tr *ngFor="let group of leadSources.controls; let i = index" [formGroupName]="i">
            <td class="common bg-navy-light text-dark">{{ i + 1 }}</td>
            <td class="common bg-navy-light text-dark">{{ dailyMarketingReportData[i]?.leadSourceName }}</td>
            <td class="common bg-navy-light text-dark">
              <input class="common bg-navy-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" type="number" [min]="0"
                formControlName="emoneeds" />
            </td>
            <td class="common bg-navy-light text-dark">
              <input class="common bg-navy-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" type="number" [min]="0"
                formControlName="gk" />
            </td>
            <td class="common bg-blue-light text-dark">
              {{ dailyMarketingReportData[i]?.previousDay1WithDate?.emoneeds }}
            </td>
            <td class="common bg-blue-light text-dark">
              {{ dailyMarketingReportData[i]?.previousDay1WithDate?.gk }}
            </td>
            <td class="common bg-purple-light text-dark">
              {{ dailyMarketingReportData[i]?.previousDay2WithDate?.emoneeds }}
            </td>
            <td class="common bg-purple-light text-dark">
              {{ dailyMarketingReportData[i]?.previousDay2WithDate?.gk }}
            </td>
          </tr>
        </tbody>

      </table>
      <table [formGroup]="reviewForm">
        <thead>
          <tr>
            <th rowspan="3" class="common bg-green-dark text-bold text-white w-8">S No.</th>
            <th rowspan="3" class="common bg-green-dark text-bold text-white">Source</th>
            <th colspan="6" class="common bg-green-dark text-bold text-white">{{ timeLineDate |
              date:'dd/MM/yyyy' }}</th>
          </tr>
          <tr>
            <th colspan="3" class="common bg-green-dark text-bold text-white w-30">Emoneeds</th>
            <th colspan="3" class="common bg-green-dark text-bold text-white w-30">GK</th>
          </tr>
          <tr>
            <th class="common bg-green-dark text-bold text-white">Request</th>
            <th class="common bg-green-dark text-bold text-white">Pending</th>
            <th class="common bg-green-dark text-bold text-white">Done</th>
            <th class="common bg-green-dark text-bold text-white">Request</th>
            <th class="common bg-green-dark text-bold text-white">Pending</th>
            <th class="common bg-green-dark text-bold text-white">Done</th>
          </tr>
        </thead>
        <tbody>
          <tr formGroupName="practoReview">
            <td class="common bg-blue-light text-dark">{{ dailyMarketingReportData.length + 1 }}</td>
            <td class="common bg-blue-light text-dark px-2">Practo Review</td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="emoneedsPractorRequest" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="emoneedsPractorPending" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="emoneedsPractorDone" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="gkPractorRequest" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="gkPractorPending" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="gkPractorDone" type="number" />
            </td>
          </tr>
          <tr formGroupName="gmbReview">
            <td class="common bg-blue-light text-dark">{{ dailyMarketingReportData.length + 2 }}</td>
            <td class="common bg-blue-light text-dark px-2">GMB Review</td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="emoneedsGmbRequest" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="emoneedsGmbPending" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0"
                formControlName="emoneedsGmbDone" type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0" formControlName="gkGmbRequest"
                type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0" formControlName="gkGmbPending"
                type="number" />
            </td>
            <td class="common bg-blue-light text-dark">
              <input class="common bg-blue-light text-dark" (paste)="onPaste($event)"
                (keypress)="validateInput($event, numericRegex, $event.target)" [min]="0" formControlName="gkGmbDone"
                type="number" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end my-10">
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</section>