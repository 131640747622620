import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'; // Add FormsModule
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table'; // Import PrimeNG TableModule
import { ButtonModule } from 'primeng/button'; // Import PrimeNG ButtonModule (optional if using PrimeNG buttons)
import { InputNumberModule } from 'primeng/inputnumber'; // Import PrimeNG InputNumberModule (for number inputs)
import { CalendarModule } from 'primeng/calendar'; // Import PrimeNG CalendarModule for date inputs

import { MonthlyTargetLeadsComponent } from './monthly-target-leads.component';
import { MonthlyTargetLeadsRoutingModule } from './monthly-target-leads-routing.module';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    MonthlyTargetLeadsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MonthlyTargetLeadsRoutingModule,
    CalendarModule,
    DropdownModule
  ]
})
export class MonthlyTargetLeadsModule { }
