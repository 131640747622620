import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { RppPatientService } from '../rpp-patient-list/services/rpp-patient.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { monthlyLeadsTargetService } from '../monthly-target-leads/Services/monthly-target-leads-service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-lead-input',
  templateUrl: './lead-input.component.html',
  styleUrls: ['./lead-input.component.scss']
})
export class LeadInputComponent implements OnInit {
  dailyTargetForm!: FormGroup;
  reviewForm!: FormGroup;
  currentDate: any;
  previousDay1: any;
  previousDay2: any;
  selectedYear: any;
  selectedMonth: any;
  selectedDate: Date;
  currentYear: number;
  timeLineDate: Date = new Date();
  numericRegex: RegExp = /^\d+$/;
  dailyMarketingReportData: any[] = [];
  maxSelectableDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private rppService: RppPatientService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private monthlyTargetLeadService: monthlyLeadsTargetService,
  ) {
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.handleFormsInit();
    this.onDateChange(this.timeLineDate);
  }

  //handle form initialize
  handleFormsInit() {
    this.dailyTargetForm = this.fb.group({
      leadSources: this.fb.array([]),
    });

    this.reviewForm = this.fb.group({
      practoReview: this.fb.group({
        emoneedsPractorRequest: [0],
        emoneedsPractorPending: [0],
        emoneedsPractorDone: [0],
        gkPractorRequest: [0],
        gkPractorPending: [0],
        gkPractorDone: [0]
      }),
      gmbReview: this.fb.group({
        emoneedsGmbRequest: [0],
        emoneedsGmbPending: [0],
        emoneedsGmbDone: [0],
        gkGmbRequest: [0],
        gkGmbPending: [0],
        gkGmbDone: [0]
      })
    });
  }

  //handle validate date format for manual entry
  validateManualDate(event: any): void {
    const inputValue = event.target.value.trim();

    // Validate date format: dd/mm/yyyy
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!datePattern.test(inputValue)) {
      this.toastr.error('Invalid date format. Please use dd/mm/yyyy.');
      this.timeLineDate = null;
      return;
    }

    const [day, month, year] = inputValue.split('/').map(Number);
    const parsedDate = new Date(year, month - 1, day);

    if (
      isNaN(parsedDate.getTime()) ||
      parsedDate > this.maxSelectableDate
    ) {
      this.toastr.error('Invalid date. Please check and try again.');
      this.timeLineDate = null;
      return;
    }

    this.timeLineDate = parsedDate;
    this.onDateChange(parsedDate);
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text') || '';
  
    if (!/^\d+$/.test(pastedText)) {
      event.preventDefault();
      this.toastr.warning('Only numbers are allowed!', 'Warning');
    }
  }

  //handle on change date and handle update previous dates in table and get daily target lead
  onDateChange(date: Date): void {
    this.selectedDate = date;
    this.selectedYear = date.getFullYear().toString();
    this.selectedMonth = (date.getMonth() + 1).toString().padStart(2, '0');

    this.updatePreviousDays();
    this.getDailyTargetLead();
  }

  //handle update previous days in table 
  updatePreviousDays(): void {
    this.currentDate = new Date(this.timeLineDate);
    const previous1 = new Date(this.currentDate);
    previous1.setDate(this.currentDate.getDate() - 1);
    this.previousDay1 = this.formatDate(previous1);

    const previous2 = new Date(this.currentDate);
    previous2.setDate(this.currentDate.getDate() - 2);
    this.previousDay2 = this.formatDate(previous2);
  }

  //handle return formated date YYYY-MM-DD
  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  //handle getting daily target lead and mapping as required
  getDailyTargetLead() {
    this.spinner.show();
    forkJoin({
      leadSourceList: this.rppService.getAllLeadSource(),
      dailyTargetLead: this.monthlyTargetLeadService.getDailyLeadSourceInput(this.formatDate(this.selectedDate))
    }).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        if (res.leadSourceList?.data?.length) {
          this.dailyMarketingReportData = res.leadSourceList?.data.map((item: any, index: number) => {
            const leadSourceName = item?.lead_source;

            const currentDayData = res?.dailyTargetLead.data[0] || {};
            const prevDay1Data = res?.dailyTargetLead.data[1] || {};
            const prevDay2Data = res?.dailyTargetLead.data[2] || {};

            this.handlePatchReviewFormValue(currentDayData);

            return {
              leadSourceName,
              daily_leads_input_id: currentDayData?.daily_leads_input_id,
              currentDayWithDate: {
                emoneeds: currentDayData?.Emoneeds_11?.[leadSourceName] || 0,
                gk: currentDayData?.GK_41?.[leadSourceName] || 0,
              },
              previousDay1WithDate: {
                emoneeds: prevDay1Data?.Emoneeds_11?.[leadSourceName] || 0,
                gk: prevDay1Data?.GK_41?.[leadSourceName] || 0,
              },
              previousDay2WithDate: {
                emoneeds: prevDay2Data?.Emoneeds_11?.[leadSourceName] || 0,
                gk: prevDay2Data?.GK_41?.[leadSourceName] || 0,
              }
            };
          });
          this.initializeDailyTargetForm();
        } else {
          console.warn('No lead source data found.');
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.error('Error fetching data:', err);
      }
    });
  }

  handlePatchReviewFormValue(currentDayData: any) {
    const Emoneeds_11 = currentDayData?.Emoneeds_11 || {};
    const GK_41 = currentDayData?.GK_41 || {};

    this.reviewForm.patchValue({
      practoReview: {
        emoneedsPractorRequest: Emoneeds_11['practo-Review']?.Request || 0,
        emoneedsPractorPending: Emoneeds_11['practo-Review']?.Pending || 0,
        emoneedsPractorDone: Emoneeds_11['practo-Review']?.Done || 0,
        gkPractorRequest: GK_41['practo-Review']?.Request || 0,
        gkPractorPending: GK_41['practo-Review']?.Pending || 0,
        gkPractorDone: GK_41['practo-Review']?.Done || 0,
      },
      gmbReview: {
        emoneedsGmbRequest: Emoneeds_11['GMB_Review']?.Request || 0,
        emoneedsGmbPending: Emoneeds_11['GMB_Review']?.Pending || 0,
        emoneedsGmbDone: Emoneeds_11['GMB_Review']?.Done || 0,
        gkGmbRequest: GK_41['GMB_Review']?.Request || 0,
        gkGmbPending: GK_41['GMB_Review']?.Pending || 0,
        gkGmbDone: GK_41['GMB_Review']?.Done || 0,
      }
    });
  }




  //handle initilize daily target lead form
  initializeDailyTargetForm(): void {
    if (!this.dailyMarketingReportData || !this.dailyMarketingReportData.length) {
      console.warn('No data available to initialize the form');
      return;
    }

    this.dailyTargetForm = this.fb.group({
      leadSources: this.fb.array(
        this.dailyMarketingReportData.map((data) =>
          this.fb.group({
            emoneeds: [data.currentDayWithDate?.emoneeds || 0],
            gk: [data.currentDayWithDate?.gk || 0],
          })
        )
      ),
    });
  }

  get leadSources(): FormArray {
    if (!this.dailyTargetForm) {
      console.warn('dailyTargetForm is not initialized.');
      return new FormArray([]);
    }
    return this.dailyTargetForm.get('leadSources') as FormArray;
  }

  //handle patcing daily target lead data
  patchDailyTargetFormData(): void {
    if (!this.dailyMarketingReportData || !this.dailyTargetForm) {
      console.warn('Data or form is not available');
      return;
    }

    const leadSources = this.dailyTargetForm.get('leadSources') as FormArray;

    if (!leadSources) {
      console.error('FormArray "leadSources" not found in the form');
      return;
    }

    while (leadSources.length > 0) {
      leadSources.removeAt(0);
    }

    this.dailyMarketingReportData.forEach((data) => {
      leadSources.push(
        this.fb.group({
          emoneeds: [data.currentDayWithDate?.emoneeds || 0],
          gk: [data.currentDayWithDate?.gk || 0],
        })
      );
    });
  }

  //handle daily target lead form submit
  handleDailyTargetSubmit(): void {
    const leadSources = this.dailyTargetForm.get('leadSources') as FormArray;
    const result = {
      daily_leads_input_id: this.dailyMarketingReportData.find(data => data.daily_leads_input_id)?.daily_leads_input_id || null,
      Emoneeds_11: { hosp_id: 11 },
      GK_41: { hosp_id: 41 }
    };
  
    this.dailyMarketingReportData.forEach((data, index) => {
      const leadSourceGroup = leadSources.at(index) as FormGroup;
  
      result.Emoneeds_11[data.leadSourceName] = leadSourceGroup.get('emoneeds')?.value || 0;
      result.GK_41[data.leadSourceName] = leadSourceGroup.get('gk')?.value || 0;
    });
  
    this.handleUpdateReviewFormValue(result);
  
    result.daily_leads_input_id ? this.updateDailyTargetLead(result) : this.createDailyTargetLead(result);
  }
  

  //handle updating review form data
  handleUpdateReviewFormValue(result: any) {
    const practoReviewValues = this.reviewForm.get('practoReview')?.value;
    const gmbReviewValues = this.reviewForm.get('gmbReview')?.value;

    result.Emoneeds_11['practo-Review'] = {
      Request: practoReviewValues?.emoneedsPractorRequest || 0,
      Pending: practoReviewValues?.emoneedsPractorPending || 0,
      Done: practoReviewValues?.emoneedsPractorDone || 0
    };

    result.Emoneeds_11['GMB_Review'] = {
      Request: gmbReviewValues?.emoneedsGmbRequest || 0,
      Pending: gmbReviewValues?.emoneedsGmbPending || 0,
      Done: gmbReviewValues?.emoneedsGmbDone || 0
    };

    result.GK_41['practo-Review'] = {
      Request: practoReviewValues?.gkPractorRequest || 0,
      Pending: practoReviewValues?.gkPractorPending || 0,
      Done: practoReviewValues?.gkPractorDone || 0
    };

    result.GK_41['GMB_Review'] = {
      Request: gmbReviewValues?.gkGmbRequest || 0,
      Pending: gmbReviewValues?.gkGmbPending || 0,
      Done: gmbReviewValues?.gkGmbDone || 0
    };
  }


  //handle updating daily target lead data
  updateDailyTargetLead(result: any) {
    this.spinner.show();
    const daily_leads_input_id = result.daily_leads_input_id;
    delete result.daily_leads_input_id;

    this.monthlyTargetLeadService.updateDailyLeadSourceInput(
      daily_leads_input_id,
      this.formatDate(this.selectedDate),
      result
    ).subscribe({
      next: res => {
        this.spinner.hide();
        if (res) {
          this.toastr.success('Daily Target Lead Updated Successfull.');
        }
      },
      error: err => {
        this.spinner.hide();
        this.toastr.error('No changes found')
      }
    })
  }

  //handle creating daily target lead data
  createDailyTargetLead(result: any) {
    this.spinner.show();
    delete result.daily_leads_input_id;

    const payload = {
      daily_input_date: this.formatDate(this.selectedDate),
      ...result
    }

    this.monthlyTargetLeadService.createDailyLeadSourceInput(
      this.selectedMonth,
      this.selectedYear,
      payload
    ).subscribe({
      next: res => {
        this.spinner.hide();
        if (res) {
          this.toastr.success('Daily Target Lead Created Successfull.');
        }
      },
      error: err => {
        this.spinner.hide();
        this.toastr.error('Error While Creating Lead.')
      }
    })
  }
  //handle validate user to enter number only 
  validateInput(event: KeyboardEvent, regex: RegExp, inputElement: HTMLInputElement): void {
    const currentValue = inputElement.value;
    if (currentValue === '0' && event.key !== '0') {
      inputElement.value = '';
    }
  
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

}