import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RppPatientService } from '../rpp-patient-list/services/rpp-patient.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { monthlyLeadsTargetService } from './Services/monthly-target-leads-service';
import { forkJoin } from 'rxjs';

interface Hospital {
  name: string;
  code: number;
}

@Component({
  selector: 'app-monthly-target-leads',
  templateUrl: './monthly-target-leads.component.html',
  styleUrls: ['./monthly-target-leads.component.scss'],
})
export class MonthlyTargetLeadsComponent implements OnInit {
  marketingLeadForm!: FormGroup;
  maxDate: Date = new Date();
  timeLineDate: Date = new Date();
  currentYear: number;
  currentMonthYear: string = '';
  previousMonth1Year: string = '';
  previousMonth2Year: string = '';
  currentMonthWithYear: any;
  previousMonth1WithYear: any;
  previousMonth2WithYear: any;
  numericRegex: RegExp = /^\d+$/;
  selectedYear: any = this.timeLineDate.getFullYear();
  selectedMonth: any = this.timeLineDate.getMonth() + 1;
  selectedHospital: Hospital | undefined;
  dailyMarketingReportData: any[] = [];
  hospitalOptions: Hospital[] = [
    { name: 'Emoneeds', code: 11 },
    { name: 'GK', code: 43 },
  ];

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private rppService: RppPatientService,
    private monthlyTargetLeadService: monthlyLeadsTargetService,
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.maxDate.setDate(1);
    this.selectedHospital = this.hospitalOptions[0];
    this.onDateChange(this.timeLineDate);
  }

  //handle on date change and fatching monthly target lead data
  onDateChange(date: Date): void {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    this.selectedYear = date.getFullYear();
    this.selectedMonth = (date.getMonth() + 1).toString().padStart(2, '0');

    const selectedMonthName = monthNames[date.getMonth()];
    const monthWithYear = `${selectedMonthName}_${this.selectedYear}`;

    this.currentMonthWithYear = monthWithYear;
    let prevDate = new Date(date);
    prevDate.setMonth(prevDate.getMonth() - 1);
    this.previousMonth1WithYear = `${monthNames[prevDate.getMonth()]}_${prevDate.getFullYear()}`;

    prevDate.setMonth(prevDate.getMonth() - 1);
    this.previousMonth2WithYear = `${monthNames[prevDate.getMonth()]}_${prevDate.getFullYear()}`;
    this.updateMonthYear();

    this.getMonthlyTargetLead();
  }

  // handle updating month with year
  updateMonthYear(): void {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Current Month and Year
    this.currentMonthYear = `${monthNames[this.selectedMonth - 1]} ${this.selectedYear}`;

    // Previous Month 1
    const prevMonth1 = this.selectedMonth - 1;
    const prevMonth1Year = prevMonth1 === 0 ? this.selectedYear - 1 : this.selectedYear;
    const prevMonth1Index = (prevMonth1 + 12 - 1) % 12;
    this.previousMonth1Year = `${monthNames[prevMonth1Index]} ${prevMonth1Year}`;

    // Previous Month 2
    const prevMonth2 = this.selectedMonth - 2;
    const prevMonth2Year = prevMonth2 <= 0 ? this.selectedYear - 1 : this.selectedYear;
    const prevMonth2Index = (prevMonth2 + 12 - 1) % 12;
    this.previousMonth2Year = `${monthNames[prevMonth2Index]} ${prevMonth2Year}`;
  }

  //handle get monthly target lead and maping data as required ( according to table UI )
  getMonthlyTargetLead() {
    this.spinner.show();
    forkJoin({
      leadSourceList: this.rppService.getAllLeadSource(),
      leadTargetMonthWithYaer: this.monthlyTargetLeadService.getMonthYearHospitalWise(
        this.selectedMonth,
        this.selectedYear,
        this.selectedHospital.code
      )
    }).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        if (res.leadSourceList?.data?.length) {
          this.dailyMarketingReportData = res.leadSourceList.data.map((item: any, index: number) => {
            const leadSourceName = item.lead_source;

            const currentMonthData = res.leadTargetMonthWithYaer.data[this.currentMonthWithYear] || {};
            const prevMonth1Data = res.leadTargetMonthWithYaer.data[this.previousMonth1WithYear] || {};
            const prevMonth2Data = res.leadTargetMonthWithYaer.data[this.previousMonth2WithYear] || {};

            return {
              leadSourceName,
              monthly_leads_target_id: currentMonthData.monthly_leads_target_id,
              currentMonthWithYear: {
                new: currentMonthData.new_monthly_target?.[leadSourceName] || 0,
                old: currentMonthData.old_monthly_target?.[leadSourceName] || 0,
                plan: currentMonthData.rpp_plan_monthly_target?.[leadSourceName] || 0,
              },
              previousMonth1WithYear: {
                new: prevMonth1Data.new_monthly_target?.[leadSourceName] || 0,
                old: prevMonth1Data.old_monthly_target?.[leadSourceName] || 0,
                plan: prevMonth1Data.rpp_plan_monthly_target?.[leadSourceName] || 0,
              },
              previousMonth2WithYear: {
                new: prevMonth2Data.new_monthly_target?.[leadSourceName] || 0,
                old: prevMonth2Data.old_monthly_target?.[leadSourceName] || 0,
                plan: prevMonth2Data.rpp_plan_monthly_target?.[leadSourceName] || 0,
              },
            };
          });
          this.initializeForm();
        } else {
          console.warn('No lead source data found.');
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.error('Error fetching data:', err);
      }
    });
  }

  //handle initialixe marketing Lead Form controls
  initializeForm(): void {
    if (!this.dailyMarketingReportData || !this.dailyMarketingReportData.length) {
      console.warn('No data available to initialize the form');
      return;
    }

    const formControls: { [key: string]: any } = {};

    this.dailyMarketingReportData.forEach((data, index) => {
      formControls[`new_${index}`] = this.fb.control(0);
      formControls[`old_${index}`] = this.fb.control(0);
      formControls[`plan_${index}`] = this.fb.control(0);
    });

    this.marketingLeadForm = this.fb.group(formControls);
    this.patchFormData();
  }

  //handle patch form data if exist
  patchFormData(): void {
    if (!this.dailyMarketingReportData) return;

    this.dailyMarketingReportData.forEach((data, index) => {
      this.marketingLeadForm.patchValue({
        [`new_${index}`]: data.currentMonthWithYear.new || 0,
        [`old_${index}`]: data.currentMonthWithYear.old || 0,
        [`plan_${index}`]: data.currentMonthWithYear.plan || 0,
      });
    });
  }

  //handle create actual result and handle create and update target lead based on monthly_leads_target_id
  handleOnSubmit(): void {
    const result = {
      monthly_leads_target_id: '',
      new_monthly_target: {},
      old_monthly_target: {},
      rpp_plan_monthly_target: {}
    };

    this.dailyMarketingReportData.forEach((data, index) => {
      result.monthly_leads_target_id = data.monthly_leads_target_id;
      result.new_monthly_target[data.leadSourceName] = this.marketingLeadForm.get(`new_${index}`)?.value || 0;
      result.old_monthly_target[data.leadSourceName] = this.marketingLeadForm.get(`old_${index}`)?.value || 0;
      result.rpp_plan_monthly_target[data.leadSourceName] = this.marketingLeadForm.get(`plan_${index}`)?.value || 0;
    });

    if (result.monthly_leads_target_id == null) {
      this.createMonthlyTargetLead(result);
    } else {
      this.updateMonthlyTargetLead(result)
    }
  }

  //handle create monthly target lead
  createMonthlyTargetLead(result: any) {
    this.spinner.show();
    delete result.monthly_leads_target_id;
    this.monthlyTargetLeadService.createMonthlyLeadSourceTarget(
      this.selectedHospital.code,
      this.selectedMonth,
      this.selectedYear,
      result
    ).subscribe({
      next: res => {
        this.spinner.hide();
        if (res) {
          this.toastr.success('Monthly Target Lead Created Successfull.');
        }
      },
      error: err => {
        this.spinner.hide();
        this.toastr.error('Error While Creating Lead.')
      }
    })
  }

  //handle update monthly target lead
  updateMonthlyTargetLead(result: any) {
    this.spinner.show();
    const monthly_leads_target_id = result.monthly_leads_target_id;
    delete result.monthly_leads_target_id;
    this.monthlyTargetLeadService.updateMonthlyLeadSourceTarget(
      this.selectedHospital.code,
      this.selectedMonth,
      this.selectedYear,
      monthly_leads_target_id,
      result
    ).subscribe({
      next: res => {
        this.spinner.hide();
        if (res) {
          this.toastr.success('Monthly Target Lead Updated Successfull.');
        }
      },
      error: err => {
        this.spinner.hide();
        this.toastr.error('No changes found.')
      }
    })
  }

  //handle get monthly target lead on dropdown value change
  onDropdownChange(event: any) {
    if (event) {
      this.getMonthlyTargetLead();
    }
  }

  //handle validate user to enter number only 
  validateInput(event: KeyboardEvent, regex: RegExp, inputElement: HTMLInputElement): void {
    const currentValue = inputElement.value;
    if (currentValue === '0' && event.key !== '0') {
      inputElement.value = '';
    }
  
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text') || '';
  
    if (!/^\d+$/.test(pastedText)) {
      event.preventDefault();
      this.toastr.warning('Only numbers are allowed!', 'Warning');
    }
  }

}
