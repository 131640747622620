import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeadInputComponent } from './lead-input.component';
import { LeadInputRoutingModule } from './lead-input-routing.module';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    LeadInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LeadInputRoutingModule,
    CalendarModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class LeadInputModule { }
